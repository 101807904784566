.app-chatbot-button {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    padding: 10px;
    color: #fff;
    background-color: #d0aadaff;
    border: none;
    position: fixed;
    right: 20px;
    bottom: 25px;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.6s ease-in-out;
}
.app-chatbot-button:hover {
    transform: scale(1.1);
}
.app-chatbot-container {
    margin: 40px 0;
    display: flex;
    justify-content: center;
    position: fixed;
    right: 20px;
    bottom: 55px;
    z-index: 10000;
    box-shadow: 5px 5px 13px rgba(91, 81, 81, 0.4);
    border-radius: 5px;
}
.app-chatbot-button-icon {
    fill: #fff;
}